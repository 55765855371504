import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/NewX2',
		name: 'NewX2.vue',
		component: () => import('../views/NewX2.vue')
	},
	{
		path: '/NewX1',
		name: 'NewX1.vue',
		component: () => import('../views/NewX1.vue')
	}
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

export default router
